import React, { useEffect, useState } from "react"
import styled from "styled-components"
import parse from "html-react-parser"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-h5-audio-player/lib/styles.css'
import Icon from "../icon"
import {
    StyledAudioCard,
    StyledAudioTitle,
    StyledAudioDuration,
    StyledAudioSleepModal,
    StyledAudioModalCloseButton,
    StyledSingleAudioHeader,
    StyledSingleAudioTitle,
    StyledSingleAudioBodyWithCopy
} from "../../styledComponents/app/audioCard"
import { analyticClickEvent } from "../googleAnalytics"
import localforage from "localforage"
import { getBlobURL } from '../../functions/utils';

function AudioModal(data) {
    const identity = useIdentityContext()
    const [isOpen, setIsOpen] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [localUrl, setLocalUrl] = useState();

    useEffect(() => {
      async function getBackgroundUrl() {
        const localBackgroundImage = await localforage.getItem('sleepStarsBackground')
        if (localBackgroundImage) setLocalUrl(getBlobURL(localBackgroundImage))
      }

      getBackgroundUrl();
    }, []);

    function toggleModal(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
      let action
      if(identity.user){
        action = 'UserID: ' + identity.user.id + ' Opens'
      }
      else {
        action = 'UserID: NOT LOGGED IN Opens'
      }
      analyticClickEvent(action,'Engagement', data.audio.title, '')
    }

    function toggleModalClose(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
      let action
      if(identity.user){
        action = 'UserID: ' + identity.user.id + ' Closes'
      }
      else {
        action = 'UserID: NOT LOGGED IN Closes'
      }
      analyticClickEvent(action,'Engagement', data.audio.title, '')
    }
  
    function afterOpen() {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 300);
      });
    }
    const audio = data.audio
    return (
      <>
        <div onClick={toggleModal}>
            <StyledAudioTitle>{audio.title}</StyledAudioTitle>
            <img src={audio.image} />
            {audio.duration && <StyledAudioDuration>{audio.duration} min</StyledAudioDuration>}
        </div>
            <StyledAudioSleepModal
                isOpen={isOpen}
                afterOpen={afterOpen}
                beforeClose={beforeClose}
                onBackgroundClick={toggleModal}
                onEscapeKeydown={toggleModal}
                opacity={opacity}
                backgroundProps={{ opacity }}
                backgroundImage={audio.image}
                localImage={localUrl}
                className="single-audio-modal"
                program="sleep"
            >

                <StyledSingleAudioHeader>
                    <StyledAudioModalCloseButton onClick={toggleModalClose}>
                        <Icon name="cross" />
                    </StyledAudioModalCloseButton>
                    <div className="iconContainer">
                        <Icon name="karinagrant" />
                    </div>
                    <StyledSingleAudioTitle>{audio.title}</StyledSingleAudioTitle>
                </StyledSingleAudioHeader>
                <StyledSingleAudioBodyWithCopy className="sleep-audio">
                    
                    
                    {audio.content && 
                      audio.content.map(info => {
                      return (

                        <div className={`${audio.title.includes('Sleep instructions') ? 'content-wrapper has-tabs' : 'content-wrapper'}`}>
                        {/* Quick Start */}
                        {info.intro && parse(info.intro)}
                        {info.handToHeart && parse(info.handToHeart)}
                        {info.week1 && parse(info.week1)}
                        {info.week1Audio && info.week1Audio}
                        {info.week2 && parse(info.week2)}
                        {info.week3Audio && info.week3Audio}
                        {info.week3 && parse(info.week3)}
                        {info.week3Audio && info.week3Audio}
                        {info.endPara && parse(info.endPara)}

                        {/* Hand 2 heart */}
                        {info.audio && info.video && audio.title.includes('Hand to heart') &&
                        <>
                          <h3>Watch the Hand to heart demo</h3>
                          {info.video.map(video => {
                            return (
                              <>
                                <video  controls><source src={video?.handToHeart?.props?.videoURL || 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/h2h-caricature.mp4'} type='video/mp4' /></video>
                              </>
                            )
                          })}
                          <h3 style={{'marginTop':'2.6rem','borderBottom':'1px solid #fff','paddingBottom':'0.8rem'}}>Or listen to the audio</h3>
                          {info.audio &&   
                            <Accordion>
                              {info.audio.map(audios => {
                                return (
                                  <>
                                  <AccordionItem>
                                    <AccordionItemHeading>
                                      <AccordionItemButton className="accordion__button audio-module-item">
                                      {parse(audios.title)}
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      {audios.audioMp3}
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                  </>
                                )
                              })}
                              </Accordion>
                            }
                        </>
                        }

                        {/* Sleep Instructions */}
                        {info.audio && info.video && audio.title.includes('Sleep instructions') &&
                        <>
                          <div className="video-audio-tabs">
                          <Tabs>
                            <TabList>
                              <Tab>Listen</Tab>
                              <Tab>Watch</Tab>
                            </TabList>
                            <TabPanel>
                            {info.audio &&   
                              <Accordion preExpanded={['An example sequence']}>
                                {info.audio.map((audios, index) => {
                                  // const isExpanded = index < 1
                                  return (
                                    <>
                                    <AccordionItem 
                                      key={audios.title}
                                      uuid={audios.title}
                                      //dangerouslySetExpanded={isExpanded}
                                    >
                                      <AccordionItemHeading>
                                        <AccordionItemButton className="accordion__button audio-module-item">
                                        {parse(audios.title)}
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        {audios.audioMp3}
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                    </>
                                  )
                                })}
                              </Accordion>
                            }
                            </TabPanel>
                            <TabPanel>
                            <Accordion>
                            {info.video.map(video => {
                              return (
                                <>
                                  <AccordionItem>
                                    <AccordionItemHeading>
                                      <AccordionItemButton className="accordion__button audio-module-item">
                                      {parse(video.title)}
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                    {video.video}
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                </>
                              )
                            })}
                          </Accordion>
                            </TabPanel>
                          </Tabs>
                          </div>
                        </>
                        }

                        {/* Permissions for reporgramming the brain */}
                        {info.audio && audio.title.includes('Permissions for reprogramming the mind') &&
                        <>
                          {info.audio &&   
                            <Accordion preExpanded={['An aversion of going to bed']}>
                              {info.audio.map(audios => {
                                return (
                                  <>
                                  <AccordionItem
                                    key={audios.title}
                                    uuid={audios.title}
                                  >
                                    <AccordionItemHeading>
                                      <AccordionItemButton className="accordion__button audio-module-item">
                                      {parse(audios.title)}
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      {audios.audioMp3}
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                  </>
                                )
                              })}
                              </Accordion>
                            }
                        </>
                      }
                        </div>
                      )
                    })
                    }

                </StyledSingleAudioBodyWithCopy>
            </StyledAudioSleepModal>
        
      </>
    )
  }
  
const FadingBackground = styled(BaseModalBackground)`
    opacity: ${(props) => props.opacity};
    transition: all 0.3s ease-in-out;
`

const AudioSleepCard = props => {
    return (        
        <StyledAudioCard>
            <ModalProvider backgroundComponent={FadingBackground}>
                <AudioModal audio={props} />
            </ModalProvider>
        </StyledAudioCard>
    )
}


export default AudioSleepCard

import React from "react"
import ReactPlayer from "react-player"
import {
    ReactVideoPlayer,
    ReactAudioPlayer
 } from "../components/reactPlayer"

const fixture = [
    {
        programName: "Permission to Reset Sleep",
        featuredImage: "",
        type: 'permission-reset-sleep',
        featuredImage: "/app/images/quantum-touch/morning-meditation.jpg",
        tags: ['quantum-touch'],
        audioFiles: [
            {
                title: "Quick start",
                image: "/app/images/permission-to-sleep/quick-start.jpg",
                content: [
                    {
                        intro: "<p>If you have little time and want to get started then start with the track order below. Play the relevant audio until you fall asleep. You can loop the file if you wish.</p><h2>The 3 audios below are the core recordings.</h2><h2>Hand to Heart Video</h2><p>Please watch this video.</p>",
                        handToHeart:"<video controls><source src='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/h2h-caricature.mp4' type='video/mp4'></video>",
                        // <ReactPlayer 
                        // width='100%'
                        // className='react-player'
                        // url="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/h2h-caricature.mp4" 
                        // controls={false}
                        // config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        // onContextMenu={e => e.preventDefault()}
                        // onPlay={e => analyticClickEvent(action,'Video','Play: '+ title)} 
                        // onPause={e => analyticClickEvent(action,'Video','Pause: '+ title)} 
                        // onEnded={e => analyticClickEvent(action,'Video','Ended: '+ title)} 
                    //   />,
                        week1: '<h2 class="week-label">Week 1</h2><h3>Short Hand to Heart + Conditioning Relaxation</h3><p>Listen to this track for the first week each night in bed</p>',
                        week1Audio: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/conditioning-relaxation-combined-tracks/sh2h-cr-faster.mp3' title="Quick Start : Short Hand to Heart + Conditioning Relaxation" />,
                        week2: '<h2 class="week-label">Week 2</h2><h3>Short hand to heart + safe place + conditioning relaxation</h3><p>Listen to this track for the second week each night in bed</p>',
                        week2Audio: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/conditioning-relaxation-combined-tracks/sh2h-safe-place-conditioning-relaxation-faster-speed.mp3' title='Sleep : Week 2 Short hand to heart + safe place + conditioning relaxation' />,
                        week3: '<h2 class="week-label">Week 3</h2><h3>Short hand 2 heart + safe place + counting</h3><p>Listen to this track for the third week each night in bed </p>',
                        week3Audio: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/conditioning-relaxation-combined-tracks/sh2h-safe-place-counting-slow.mp3' title='Sleep : Week 3 Short hand 2 heart + safe place + counting' />,
                        endPara: '<p class="endpara">Feel free to explore the rest of the program in your own time. The tracks above are the bare essentials of what you need for success. </p>'
                    }
                ]
            },
            {
                title: "Hand to heart",
                image: "/app/images/permission-to-sleep/hand-heart.jpg",
                showTabs: false,
                content: [
                    {
                        audio: [
                            {
                                title: 'Long version (8min)',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/combined.mp3' title='Sleep : Hand 2 heart Long version' />,
                            },
                            {
                                title: 'Short version (4min)',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/short-hand-to-heart/combined.mp3' title='Sleep : Hand 2 heart Short version' />,
                            },
                        ],
                        video: [
                            {
                                title: '<h2>Hand to heart</h2>',
                                handToHeart: <ReactVideoPlayer 
                                    videoURL="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/h2h-caricature.mp4"
                                    title="Hand to heart video"
                                />,
                            }
                        ],
                    }
                ]
            },
            {
                title: "Sleep instructions",
                image: "/app/images/permission-to-sleep/sleep-instructions.jpg",
                showTabs: "true",
                content: [
                    {
                        audio: [
                            {
                                title: 'An example sequence',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/an-example-sequence.mp3' title='Sleep : Sleep instructions : An example sequence' />,
                            },
                            {
                                title: 'Understanding Insomnia',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/understanding-insomnia.mp3' title='Sleep : Sleep instructions : Understanding Insomnia' />,
                            },
                            {
                                title: 'What is permissioning and how does it work',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/what-is-permissioning-and-how-does-it-work.mp3' title='Sleep : Sleep instructions : What is permissioning and how does it work' />,
                            },
                            {
                                title: 'So how does the program work',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/so-how-does-the-progrm-work.mp3' title='Sleep : Sleep instructions : So how does the program work' />,
                            },
                            {
                                title: 'Specific sleep issues',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/specific-sleep-issues.mp3' title='Sleep : Sleep instructions : Specific sleep issues' />,
                            },
                            {
                                title: 'Do you always start with hand to heart',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/do-you-always-start-with-hand-to-heart.mp3' title='Sleep : Sleep instructions : Do you always start with hand to heart' />,
                            },
                            {
                                title: 'Customizing your sleep plan and sleep tips',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/customizing-your-sleep-plan-and-sleep-tips.mp3' title='Sleep : Sleep instructions : Customizing your sleep plan and sleep tip' />,
                            },
                            {
                                title: 'If your hand gets tired',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/if-your-hand-gets-tired.mp3' title='Sleep : Sleep instructions : If your hand gets tired' />,
                            },
                            {
                                title: 'Following along',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/following-along.mp3' title='Sleep : Sleep instructions : Following along' />,
                            },
                            {
                                title: 'What does permission actually mean in this context',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/what-does-permission-actually-mean-in-this-context.mp3' title='Sleep : Sleep instructions : What does permission actually mean in this context' />,
                            },
                            {
                                title: 'Does it always work',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/does-it-always-work.mp3' title='Sleep : Sleep instructions : Does it always work' />,
                            },
                            {
                                title: 'What if my mind can\'t focus',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/what-if-my-mind-cant-focus.mp3' title="Sleep instructions : What if my mind can\'t focus" />,
                            },
                            {
                                title: 'Disclaimer',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/disclaimer.mp3' title='Sleep : Sleep instructions : Disclaimer' />,
                            },
                            
                        ],
                        video: [
                            {
                                title: 'An example Permission',
                                video: <ReactVideoPlayer 
                                    videoURL="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/example-permission-720.mp4"
                                    title="Sleep instructions : An example Permission"
                                />,
                            },
                            {
                                title: 'Switching hands',
                                video: <ReactVideoPlayer 
                                    videoURL="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/switch-hands-720.mp4"
                                    title="Sleep instructions : Switching hands"
                                />,
                            },
                            {
                                title: 'The journey from Hand to Heart',
                                video: <ReactVideoPlayer 
                                videoURL="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/how-long-journey-h2h-720.mp4"
                                title="Sleep instructions : The journey from Hand to Heart"
                            />,
                            },
                            {
                                title: 'Staying present with the Sleep Permissions',
                                video: <ReactVideoPlayer 
                                    videoURL="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/staying-present-with-permissions-720.mp4"
                                    title="Sleep instructions : Staying present with the Sleep Permissions"
                                />,
                            },
                            {
                                title: 'What does Permission mean',
                                video: <ReactVideoPlayer 
                                    videoURL="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/what-does-permission-mean-720.mp4"
                                    title="Sleep instructions : What does Permission mean"
                                />,
                            },
                            {
                                title: 'What are the combination tracks?',
                                video: <ReactVideoPlayer 
                                    videoURL="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/combination-tracks-720.mp4"
                                    title="Sleep instructions : What are the combination tracks?"
                                />,
                            },
                            {
                                title: 'When is the best time to practice?',
                                video: <ReactVideoPlayer 
                                    videoURL="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/when-to-use-techniques-720.mp4"
                                    title="Sleep instructions : When is the best time to practice?"
                                />,
                            }
                        ],
                    }
                ]
            },
            {
                title: "Permissions for sleep",
                image: "/app/images/permission-to-sleep/permissions-for-sleep-with-speed-options.jpg",
                content: [
                    {
                        intro: "<p>These are the same tracks as Quick Start with different speed options.</p>",
                        week1: '<h2 class="week-label">Week 1</h2>Conditioning relaxation</p>',
                        week1Audio: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/conditioning-relaxation-combined-tracks/sh2h-cr-faster.mp3' title='Sleep : Permissions for sleep : Week 1 Conditioning relaxation' />,
                        week2: '<h2 class="week-label">Week 2</h2><h3>Safe space</h3><p>Listen to this track for the second week each night in bed.</p>',
                        week2Audio: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/conditioning-relaxation-combined-tracks/sh2h-safe-place-conditioning-relaxation-faster-speed.mp3?v12' title='Sleep : Permissions for sleep : Week 2 Safe space' />,
                        week3: '<h2 class="week-label">Week 3</h2><h3>Counting</h3><p>Listen to this track for the third week each night in bed.</p>',
                        week3Audio: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/conditioning-relaxation-combined-tracks/sh2h-safe-place-counting-slow.mp3?v2' title='Sleep : Permissions for sleep : Week 3 Counting' />,
                        endPara: '<p class="endpara">After the 21 day program please choose which of any of the tracks you wish to listen to in bed.</p><p>Please note you need to do the same track for at least 3 nights running.</p>'
                    }
                ]
            },
            {
                title: "Permissions for reprogramming the mind",
                image: "/app/images/permission-to-sleep/permissions-for-reprogramming-the-mind.jpg",
                content: [
                    {
                        audio: [
                            {
                                title: 'An aversion of going to bed',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/an-aversion-of-going-to-bed/combined.mp3' title='Sleep : Permissions for reprogramming the mind : An aversion of going to bed' />,
                            },
                            {
                                title: 'Anxiety of not being able to fall asleep',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/anxiety-of-not-being-able-to-fall-asleep/combined.mp3' title='Sleep : Permissions for reprogramming the mind : Anxiety of not being able to fall asleep' />,
                            },
                            {
                                title: 'Breathing easily during sleep',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/breathing-easily-during-sleep/combined.mp3' title='Sleep : Permissions for reprogramming the mind : Breathing easily during sleep' />,
                            },
                            {
                                title: 'Feeling deserving of a rested body',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/feeling-deserving-of-a-rested-body/combined.mp3' title='Sleep : Permissions for reprogramming the mind : Feeling deserving of a rested body' />,
                            },
                            {
                                title: 'Positive thoughts before bed',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/positive-thoughts-before-bed/combined.mp3' title='Sleep : Permissions for reprogramming the mind : Positive thoughts before bed' />,
                            },
                            {
                                title: 'Being kind to your body in your regular habits',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/being-kind-to-your-body-in-your-regular-habits/combined.mp3' title='Sleep : Permissions for reprogramming the mind : Being kind to your body in your regular habits' />,
                            },
                            {
                                title: 'Relaxing the jaw',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/relaxing-the-jaw/combined.mp3' title="Permissions for reprogramming the mind : Relaxing the jaw" />,
                            },
                            {
                                title: 'Healing past trauma of not being able to sleep',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/healing-past-trauma-of-not-being-able-to-sleep/combined.mp3' title='Sleep : Permissions for reprogramming the mind : Healing past trauma of not being able to sleep' />,
                            },
                            {
                                title: 'Go to sleep stay asleep',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/go-to-sleep-stay-asleep/combined.mp3' title='Sleep : Permissions for reprogramming the mind : Go to sleep stay asleep' />,
                            },
                            {
                                title: 'Sweet dreams',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/sweet-dreams/combined.mp3' title='Sleep : Permissions for reprogramming the mind : Sweet dream' />,
                            },
                            {
                                title: 'Feeling safe while asleep',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/feeling-safe-while-asleep/combined.mp3' title='Sleep : Permissions for reprogramming the mind : Feeling safe while asleep' />,
                            },
                            {
                                title: 'Letting go into sleep',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/letting-go-into-sleep/combined.mp3' title='Sleep : Permissions for reprogramming the mind : Letting go into sleep' />,
                            },
                        ],
                    }
                ]
            },
        ],
    }
]

export default fixture